export const themeColor = [
  "#e2627d",
  "#ffd7ae",
  "#9ad9ff",
  "#fec5e3",
  "#9fc1ff",
  "#74ffd6",
  "#ff97a2",
  "#a5d0e6",
  "#03c4a1",
  "#79d1e0",
];
export const aboutMeColor = [
  "#335d2d",
  "#05386B",
  "#ea9438",
  "#d7385e",
  "#000000",
  "#de2b2b",
  "#335d2d",
  "#590995",
  "#03c4a1",
  "#726a95",
  "#1e5f74",
  "#ea9438",
  "#79d1e0",
  "#424242",
];
